*,
:after,
:before {
  box-sizing: border-box;
}

html {
  height: 100dvh;
  overflow-x: hidden;
}

button {
  box-sizing: border-box;
  transition: all 0.2s ease;

  &:after,
  :before {
    box-sizing: border-box;
  }
}

body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

div[id='header-portal'] {
  position: absolute;
  bottom: 0;

  &:has(div) {
    position: initial;
  }
}

.anchor {
  all: unset;
  position: absolute;
  box-sizing: border-box;
  cursor: pointer;
  top: 0;
  right: 0;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 5;
  height: 100%;
}

:root {
  --cornerradius12: 12px;
  --cornerradius8: 8px;
  --cornerradius6: 6px;

  --cornerradius10: 10px;
  --cornerradius9: 9px;
  --cornerradius14: 14px;
  --cornerradius16: 16px;
  --cornerradius18: 18px;
  --cornerradius20: 20px;
  --color-purple-default: #6941c6;
  --cst-content-title: #4a4a4a;
  --cst-content-background: #ffffff;
  --cst-content-description: #606060;
  --cst-content-description-secondary: #303030;
  --cst-side-bar-shop-text: #303030;
  --crystal-bell900: #606060;
  --cst-tag-quantity-text-active: #ffffff;
  --cst-tag-quantity-background-active: #6941c6;
  --cst-tag-quantity-background-default: #efefef;
  --cst-tag-quantity-text-default: #bfbfbf;
  --cst-side-bar-shop-descriprion: #606060;
  --color-red-default: #eb3c3c;
  --cst-legal-title: #303030;
  --cst-legal-text: #212121;
  --cst-legal-subtitle: #4a4a4a;
  --cst-legal-textbox-outline: #f5f5f5;
  --color-yellow-default: #fe9920;
  --color-black-default: #303030;
  --cst-side-bar-background: #fff;
  --cst-side-bar-items-text-default: #606060;
  --cst-side-bar-filter-text: #303030;
  --cst-dropitemgame-text-disabled: #60606066;
  --cst-dropitemgame-background-disabled: #fdfdfd66;
  --cst-dropitemgame-text-focused: #606060;
  --cst-card-player-info-background-default: #f9f9f9;
  --cst-card-seller-info-background-default: #ffffff;
  --cst-card-seller-info-background-pressed: #fdfdfd;
  --cst-card-seller-info-title-pressed: #8f8f8f;
  --cst-card-seller-info-outline-default: #f6f6f6;
  --cst-card-seller-info-outline-pressed: #f6f6f6;
  --cst-card-player-info-background-pressed: #efefef;
  --cst-card-player-info-title-default: #303030;
  --cst-card-player-info-subtitle-default: #606060;
  --cst-dropitemgame-outline-focused: #3f2777;
  --cst-switch-handleon-hover: #8767d1;
  --global-orange1000: #331f06;
  --cst-switch-iconon-hover: #f0ecf9;
  --global-orange900: #663d0d;
  --cst-switch-outlineon-hover: #e1d9f4;
  --global-orange800: #985c13;
  --global-orange700: #cb7a1a;
  --global-orange600: #fe9920;
  --alias-modal-on-primary-text-primary: #212121;
  --alias-modal-on-primary-text-secondary: #646464;
  --alias-properties-ultra-rare: #eb3c3c;
  --alias-outline: #f6f6f6;
  --global-orange500: #fead4d;
  --alias-properties-uncommon: #7e10d4;
  --global-orange400: #fec279;
  --cst-switch-backgroundon-pressed: #f0ecf9;
  --cst-bottombar-background: #ffffff;
  --cst-bottombar-outline: #efefef;
  --text-primary: #212121;
  --cst-bottombar-description: #303030;
  --cst-bottombar-text-price: #303030;
  --cst-bottombar-text-old-price: #8f8f8f;
  --cst-dropitem-background-focused: #fdfdfd;
  --dropitem-area-outline-typing: #efefef;
  --dropitem-area-title-pressed: #303030;
  --global-orange200: #ffebd2;
  --cst-switch-handleon-pressed: #c3b3e8;
  --global-blue900: #1d3a66;
  --cst-switch-backgroundon-hover: #f0ecf9;
  --global-blue800: #2b5799;
  --cst-dropitem-outline-focused: #3f2777;
  --cst-switch-iconon-pressed: #f0ecf9;
  --global-blue700: #3a74cc;
  --cst-switch-outlineon-pressed: #e1d9f4;
  --global-blue600: #4891ff;
  --cst-counter-icon: #6941c6;
  --cst-counter-background: #f0ecf9;
  --cst-counter-label: #303030;
  --cst-chips-potion-trailingiconbackground-disabled: #4891ff1e;
  --global-blue500: #6da7ff;
  --cst-chips-potion-trailingicon-disabled: #4891ff7f;
  --global-blue400: #91bdff;
  --cst-chips-potion-outline-focused: #3f2777;
  --global-blue300: #b6d3ff;
  --cst-scroll-scroller-default: #f6f6f6;
  --cst-chips-potion-text-focused: #4891ff99;
  --global-blue200: #dae9ff;
  --cst-dropitem-text-pressed: #8f8f8f;
  --global-blue100: #edf4ff;
  --cst-inputauth-title-disabled: #8f8f8f33;
  --cst-inputauth-background-disabled: #fdfdfd33;
  --cst-inputauth-subtitle-error: #eb3d3d;
  --cst-inputauth-background-error: #fdfdfd;
  --cst-inputauth-outline-error: #eb3d3d;
  --cst-inputauth-textarea-intype: #303030;
  --cst-inputauth-background-intype: #fdfdfd;
  --cst-inputauth-outline-intype: #6941c699;
  --cst-inputauth-title-focused: #8f8f8f;
  --cst-inputauth-title-hover: #8f8f8f;
  --cst-inputauth-background-hover: #fdfdfd;
  --cst-inputauth-outline-default: #f6f6f6;
  --cst-inputauth-background-default: #fdfdfd;
  --cst-authorization-quaternarytext: #6941c6;
  --cst-authorization-tertiarytext: #9e9d9e;
  --cst-drop-listsort-icon-default: #bfbfbf;
  --global-green400: #8cd674;
  --cst-drop-listsort-background-hover: #fcfcfc;
  --global-green300: #b3e4a3;
  --global-pink300: #eb88c3;
  --global-pink600: #e762b1;
  --cst-toast-outline: #f6f6f6;
  --cst-toast-background: #fff;
  --cst-toast-title: #212121;
  --cst-toast-description: #9e9d9e;
  --cst-indicators-off: #efefef;
  --cst-progress-indicator-background-balance: #edf4ff;
  --cst-progress-indicator-indicator-balance: #4891ff;
  --cst-progress-indicator-indicator-successfully: #40bb18;
  --cst-progress-indicator-background-successfully: #ecf8e8;
  --cst-progress-indicator-background-error: #fdecec;
  --cst-progress-indicator-indicator-error: #eb3c3c;
  --cst-progress-indicator-background-warn: #fff5e9;
  --cst-progress-indicator-indicator-warn: #fe9920;
  --cst-history-content-background: #f9f9f9;
  --cst-history-content-text: #606060;
  --cst-history-cards-text: #606060;
  --cst-history-tag-status-text-success: #40bb18;
  --cst-history-tag-status-text-warn: #fe9920;
  --cst-history-tag-status-text-danger: #eb3c3c;
  --cst-history-cards-background: #f9f9f9;
  --cst-history-cards-title: #8f8f8f;
  --cst-history-cards-subtitle: #303030;
  --cst-tag-status-background-warn: #fe99201a;
  --cst-tag-status-background-danger: #eb3c3c1a;
  --cst-tag-status-background-success: #40bb181a;
  --cst-cards-history-text: #303030;
  --cst-tag-status-text-sucess: #40bb18;
  --cst-tag-status-text-process: #fe9920;
  --cst-tag-status-text-closed: #eb3c3c;
  --cst-cards-history-subtitle: #8f8f8f;
  --global-green200: #d9f1d1;
  --cst-authorization-secondarytext: #d0d0d0;
  --cst-drop-listsort-text-hover: #303030;
  --global-green100: #ecf8e8;
  --cst-purchasecheckout-background: #fdfdfd;
  --global-red1000: #2f0c0c;
  --cst-drop-listsort-text-focus: #606060;
  --global-red900: #5e1818;
  --alias-properties-common: #4891ff;
  --cst-drop-listsort-icon-focus: #bfbfbf;
  --global-red800: #8d2424;
  --global-red700: #bc3030;
  --cst-drop-listsort-outline-hover: #f6f6f6;
  --global-red600: #eb3c3c;
  --global-red500: #ef6363;
  --global-red400: #f38a8a;
  --global-red300: #f7b1b1;
  --global-red200: #fbd8d8;
  --cst-buttons-quaternary-outline-pressed: #bfbfbf;
  --global-purple700: #54349e;
  --global-rainmaker600: #475467;
  --global-rainmaker400: #9198a4;
  --cst-cards-item-secondary-newpricetext-default: #303030;
  --cst-indicators-on: #7832ea;
  --global-black: #000000;
  --global-grey1000: #303030;
  --global-grey900: #606060;
  --cst-dropitem-text-default: #606060;
  --cst-accordion-title-default: #303030;
  --global-grey800: #8f8f8f;
  --global-grey600: #efefef;
  --global-grey500: #f2f2f2;
  --cst-tabitemfilter-text-default: #606060;
  --global-grey400: #f6f6f6;
  --cst-sidebar-background: #fdfdfd;
  --cst-tags-background-legendary: var(--alias-properties-legendary);
  --cst-topupcashoutmethod-title: #303030;
  --cst-purchasecheckout-outline: #f2f2f2;
  --cst-footer-title: #303030;
  --cst-footer-background: #ffffff;
  --cst-tabitem-primary-background-default: #ffffff;
  --cst-recommendation-text: #212121;
  --cst-price-card-outline-checkout: #f2f2f2;
  --cst-switch-outlineon-focused: #3f2777;
  --cst-diagram-date-hoverdown: #8f8f8f;
  --cst-dropitemgame-background-focused: #fdfdfd;
  --cst-chips-cat-text-disabled: #fe99207f;
  --cst-diagram-text-hoverdown: #606060;
  --cst-dropitemgame-text-pressed: #8f8f8f;
  --cst-diagram-backgroundhandle-hoverdown: #40bb1819;
  --cst-dropitemgame-background-pressed: #f9f9f9;
  --cst-chips-cat-trailingicon-disabled: #fe99207f;
  --cst-diagram-outlinehandle-hoverdown: #40bb1866;
  --cst-dropitemgame-text-hover: #303030;
  --cst-chips-cat-background-disabled: #fe992019;
  --cst-diagram-handle-hoverdown: #40bb18;
  --cst-chips-cat-text-focused: #fe992099;
  --cst-checkbox-icon-hover-active: #fdfdfd;
  --cst-diagram-lineardiagram-hoverdown: #40bb180f;
  --cst-chips-cat-trailingiconbackground-disabled: #fe99201e;
  --cst-checkbox-background-hover-active: #6941c6cc;
  --cst-diagram-diagram-hoverdown: #40bb18;
  --cst-chips-cat-trailingicon-focused: #fe992099;
  --cst-diagram-icon-hoverdown: #40bb18;
  --cst-chips-cat-outline-focused: #3f2777;
  --cst-diagram-backgroundicon-hoverdown: #40bb187f;
  --cst-dropitemgame-background-default: #fdfdfd;
  --cst-diagram-lineardiagram-hoverup: #40bb180f;
  --cst-switch-outlineon-disabled: #e1d9f466;
  --cst-chips-cat-background-hover: #fe992019;
  --cst-diagram-icon-hoverup: #40bb18;
  --cst-additional-info-icon-orange: #fe9920;
  --cst-switch-handleon-disabled: #e1d9f4;
  --cst-chips-cat-background-pressed: #fe992007;
  --cst-diagram-backgroundicon-hoverup: #40bb187f;
  --cst-switch-backgroundon-disabled: #f0ecf9;
  --cst-chips-cat-text-hover: #fe9920;
  --cst-diagram-date-hoverup: #8f8f8f;
  --cst-switch-iconon-disabled: #c3b3e8;
  --cst-additional-info-backgroundicon-orange: #fe99207f;
  --cst-chips-cat-trailingicon-default: #fe992099;
  --cst-diagram-amount-hoverup: #303030;
  --cst-additional-info-background-orange: #fe99200c;
  --cst-additional-info-background-red: #eb3c3c0d;
  --cst-chips-cat-outline-default: #fe992019;
  --cst-diagram-text-hoverup: #606060;
  --cst-chips-cat-outline-hover: #fe99207f;
  --cst-diagram-lineardiagram-default: #40bb180f;
  --cst-chips-cat-trailingiconbackground-default: #fe99203f;
  --cst-diagram-diagram-default: #40bb18;
  --cst-additional-info-title-orange: #fe9920;
  --cst-additional-info-title-red: #eb3c3c;
  --cst-diagram-icon-default: #40bb18;
  --cst-chips-cat-trailingiconbackground-pressed: #fe992059;
  --cst-diagram-backgroundicon-default: #40bb187f;
  --cst-chips-cat-trailingiconbackground-focused: #fe99203f;
  --cst-diagram-date-default: #8f8f8f;
  --cst-inputauth-title-error: #8f8f8f;
  --cst-diagram-amount-default: #303030;
  --cst-inputauth-textarea-error: #303030;
  --cst-diagram-text-default: #606060;
  --cst-diagramtooltip-tip-primary: #fdfdfd;
  --cst-diagramtooltip-amount-primary: #303030;
  --cst-dropitem-background-hover: #f6f6f6;
  --global-purple100: #f0ecf9;
  --cst-side-barsale-text: #303030;
  --global-purple200: #e1d9f4;
  --cst-side-barfilter-text: #303030;
  --cst-diagramtooltip-backgroundicon-primary: #e51a2e7f;
  --cst-drop-listsort-outline-default: #f6f6f6;
  --cst-drop-listsort-background-default: #ffffff;
  --cst-drop-listsearch-outline: #f6f6f6;
  --cst-inputauth-title-default: #8f8f8f;
  --cst-drop-listsearch-background: #fdfdfd;
  --cst-drop-listprofile-amount: #8f8f8f;
  --cst-chips-simple-outline-pressed: #efefef;
  --cst-drop-listprofile-background: #fdfdfd;
  --cst-cards-checkout-info-detatils: #606060;
  --cst-cards-checkout-info-price-increase: #eb3c3c;
  --cst-cards-checkout-info-amount: #303030;
  --cst-cards-checkout-info-background: #fdfdfd;
  --cst-cards-payment-method-backgroundicon-active: #6941c67f;
  --cst-inputauth-title-filled: #8f8f8f;
  --cst-cards-payment-method-icon-active: #7832ea;
  --cst-inputauth-textarea-filled: #303030;
  --cst-cards-payment-method-outline-active: #6941c6;
  --cst-inputauth-background-filled: #fdfdfd;
  --cst-cards-payment-method-background-active: #fdfdfd;
  --cst-inputauth-outline-filled: #f6f6f6;
  --cst-cards-payment-method-outline-hover: #6941c6;
  --cst-inputauth-title-intype: #8f8f8f;
  --cst-cards-payment-method-background-hover: #fdfdfd;
  --cst-chips-egg-background-disabled: #d60f8619;
  --cst-cards-payment-method-outline-default: #f2f2f2;
  --cst-chips-egg-text-focused: #d60f867f;
  --cst-cards-payment-method-background-default: #fdfdfd;
  --cst-chips-egg-outline-focused: #3f2777;
  --cst-cards-shoping-item-title: #8f8f8f;
  --cst-chips-egg-trailingiconbackground-pressed: #d60f8659;
  --cst-cards-shoping-item-oldprice: #8f8f8f;
  --cst-input-value-outline-hover: #51459f4c;
  --cst-badge-text: #fff;
  --cst-cards-shoping-item-newprice: #303030;
  --cst-cards-item-recommendation-oldprice: #8f8f8f;
  --cst-chips-egg-trailingicon-disabled: #d60f867f;
  --cst-cards-item-recommendation-newprice: #303030;
  --cst-chips-potion-background-default: #4891ff0c;
  --cst-cards-item-recommendation-outline: #f2f2f2;
  --cst-chips-egg-text-disabled: #d60f867f;
  --cst-cards-item-recommendation-background: #fdfdfd;
  --cst-input-price-outline-disabled: #f2f2f266;
  --cst-chips-egg-outline-hover: #d60f867f;
  --cst-cards-item-page-outline: #f2f2f2;
  --cst-cards-item-page-description: #606060;
  --cst-cards-item-page-oldprice: #8f8f8f;
  --cst-input-price-textarea-filled: #606060;
  --cst-cards-item-page-newprice: #303030;
  --cst-cards-category-subtitle-hover: #606060;
  --cst-cards-category-title-hover: #303030;
  --cst-cards-category-background-hover: #fdfdfd;
  --cst-cards-category-outline-default: #f6f6f6;
  --cst-cards-category-icon-default: #8f8f8f;
  --cst-cards-catalog-icon-default: #606060;
  --cst-cards-category-backgroundicon-default: #efefef7f;
  --cst-cards-catalog-backgroundicon-default: #efefef80;
  --cst-diagramtooltip-backgroundicon-secondary: #40bb187f;
  --cst-cards-category-subtitle-default: #8f8f8f;
  --global-rainmaker300: #b5bbc2;
  --alias-properties-background-none: #efefef;
  --cst-cards-item-secondary-descriptiontext-hover: #8f8f8f;
  --global-rainmaker200: #dadde1;
  --alias-properties-icon-none: #8f8f8f;
  --cst-cards-item-secondary-oldpricetext-hover: #8f8f8f;
  --cst-side-barsale-descriprion: #606060;
  --global-rainmaker100: #edeef0;
  --alias-properties-ride: #d41057;
  --cst-cards-item-secondary-newpricetext-hover: #303030;
  --global-grey300: #f9f9f9;
  --cst-cards-item-secondary-outline-hover: #f6f6f6;
  --global-grey200: #fcfcfc;
  --cst-cards-item-secondary-background-hover: #fdfdfd;
  --global-grey100: #fdfdfd;
  --cst-cards-item-secondary-descriptiontext-default: #8f8f8f;
  --cst-cards-item-secondary-oldpricetext-default: #8f8f8f;
  --cst-tabitemfilter-text-hover: #8f8f8f;
  --global-grey700: #bfbfbf;
  --cst-input-search-text-typing: #303030;
  --cst-cards-item-secondary-outline-default: #f6f6f6;
  --global-rainmaker1000: #0e1115;
  --cst-input-search-outline-typing: #6941c6;
  --cst-cards-item-secondary-background-default: #fdfdfd;
  --global-red100: #fdecec;
  --cst-cards-item-primary-outline-default: #f6f6f6;
  --cst-cards-item-primary-background-default: #ffffff;
  --cst-diagramtooltip-icon-primary: #e51a2e;
  --cst-cards-live-feed-descripiontext-hover: #8f8f8f;
  --cst-cards-live-feed-newpricetext-hover: #303030;
  --cst-footer-desripction: #8f8f8f;
  --cst-cards-live-feed-backgroundtags-default: #f6f6f6;
  --cst-checkbox-outline-default: #efefef;
  --cst-advertisingbutton-outline-disabled: #fdfdfd66;
  --cst-advertisingbutton-text-disabled: #fdfdfd66;
  --cst-checkbox-background-default: #fdfdfd;
  --cst-advertisingbutton-outline-focused: #3f2777;
  --cst-advertisingbutton-text-focused: #fdfdfd;
  --cst-advertisingbutton-outline-default: #fdfdfd;
  --cst-cards-live-feed-outline-hover: #f6f6f6;
  --cst-tabs-cards-background-default: #f6f6f6;
  --cst-advertisingbutton-text-default: #fdfdfd;
  --cst-cards-live-feed-background-hover: #fdfdfd;
  --cst-stepperbutton-outline-disabled: #51459f07;
  --cst-cards-live-feed-outline-default: #f6f6f6;
  --cst-tabitemfilter-outline-default: #f2f2f2;
  --cst-stepperbutton-iconbackground-disabled: #6941c619;
  --cst-drop-listsort-iconopen-hover: #8f8f8f;
  --cst-stepperbutton-background-pressed: #6941c60c;
  --cst-cards-item-tertiary-background-hover: #fdfdfd;
  --cst-stepperbutton-iconbackground-pressed: #6941c67f;
  --cst-buttons-quaternary-background-pressed: #f6f6f6;
  --global-purple500: #8767d1;
  --cst-stepperbutton-iconbackground-hover: #6941c67f;
  --cst-cards-live-feed-background-default: #fff;
  --cst-tabitemfilter-outline-hover: #f2f2f2;
  --cst-stepperbutton-background-default: #51459f0c;
  --cst-dropitem-background-default: #fdfdfd;
  --cst-dropitem-value-background-default: #ffffff;
  --cst-droplist-value-background: #ffffff;
  --cst-droplist-value-text-default: #606060;
  --cst-droplist-value-outline: #f6f6f6;
  --cst-advertising-background: #eb3c3c;
  --cst-advertising-text: #fdfdfd;
  --cst-buttons-quaternary-outline-hover: #efefef;
  --global-purple300: #c3b3e8;
  --cst-stepperbutton-icon-default: #6941c6;
  --cst-filter-item-text-active: #303030;
  --cst-dropitemgame-text-default: #606060;
  --cst-diagram-handle-hoverup: #40bb18;
  --cst-cards-item-block-outline: #f6f6f6;
  --cst-bread-crumbs-symbol: #8f8f8f;
  --cst-switch-handleon-focused: #a58ddd;
  --cst-diagram-amount-hoverdown: #303030;
  --global-orange100: #fff5e9;
  --cst-input-value-background-disabled: #51459f19;
  --cst-input-value-text-hover: #6941c6;
  --cst-input-value-background-hover: #51459f0c;
  --cst-cards-shoping-item-background: #fdfdfd;
  --cst-input-value-background-default: #51459f0c;
  --cst-input-value-outline-default: #51459f19;
  --cst-input-price-trailingicon-disabled: #60606019;
  --cst-input-price-backgroundtrailingicon-disabled: #bfbfbf19;
  --cst-chips-egg-trailingiconbackground-default: #d60f863f;
  --cst-input-price-cursor-typing: #606060;
  --cst-chips-egg-background-default: #d60f860c;
  --cst-input-price-trailingicon-typing: #606060;
  --cst-chips-egg-outline-default: #d60f8619;
  --cst-input-price-backgroundtrailingicon-typing: #bfbfbf7f;
  --cst-input-price-textarea-typing: #606060;
  --cst-input-price-backgroundtrailingicon-focus: #bfbfbf7f;
  --cst-input-price-textarea-focus: #bfbfbf;
  --cst-input-price-title-focus: #8f8f8f;
  --cst-input-price-outline-focus: #2a1a4f;
  --cst-tabitem-tertiary-outline-focused: #3f2777;
  --cst-input-search-сursor-typing: #303030;
  --cst-input-price-trailingicon-hover: #606060;
  --cst-input-price-textarea-hover: #606060;
  --cst-tabitem-secondary-background-hover: #eb3c3c19;
  --cst-input-price-title-hover: #8f8f8f;
  --cst-input-price-outline-hover: #bfbfbf;
  --cst-input-price-trailingicon-default: #8f8f8f;
  --cst-input-price-backgroundtrailingicon-default: #efefef7f;
  --cst-input-price-textarea-default: #606060;
  --cst-tabitem-tertiary-background-focused: #40bb180c;
  --cst-input-price-title-default: #8f8f8f;
  --cst-input-price-outline-default: #f2f2f2;
  --cst-chips-simple-trailingicon-disabled: #bfbfbf;
  --cst-chips-simple-background-disabled: #f6f6f6;
  --cst-buttons-primary-background-hover: #8767d1;
  --cst-chips-simple-trailingiconbackground-focused: #f2f2f2;
  --cst-additional-info-title-green: #40bb18;
  --cst-chips-simple-outline-focused: #3f2777;
  --cst-chips-simple-trailingiconbackground-pressed: #efefef;
  --cst-chips-simple-text-pressed: #bfbfbf;
  --cst-side-baritems-background-hover: #fdfdfd;
  --cst-chips-simple-trailingicon-hover: #606060;
  --cst-chips-simple-outline-hover: #bfbfbf;
  --cst-chips-simple-background-hover: #fcfcfc;
  --cst-chips-simple-text-default: #606060;
  --cst-chips-simple-outline-default: #f2f2f2;
  --cst-chips-simple-background-default: #fdfdfd;
  --cst-cards-item-tertiary-descriptiontext-hover: #8f8f8f;
  --cst-input-search-background-typing: #fdfdfd;
  --cst-input-value-background-focus: #51459f0c;
  --cst-tabitem-secondary-outline-pressed: #eb3c3c3f;
  --cst-diagramtooltip-tagtext-secondary: #40bb18;
  --cst-diagramtooltip-tagbackground-secondary: #40bb180c;
  --cst-cards-item-primary-descriptiontext-hover: #5e5e5e;
  --cst-tabitem-secondary-background-pressed: #eb3c3c0c;
  --cst-diagramtooltip-line-secondary: #40bb18;
  --cst-cards-category-title-default: #303030;
  --cst-chips-cat-text-default: #fe992099;
  --cst-diagramtooltip-tagoutline-secondary: #40bb1819;
  --cst-dropitem-text-focused: #606060;
  --cst-switch-backgroundon-focused: #f0ecf9;
  --global-blue1000: #0e1d33;
  --cst-chips-cat-background-default: #fe99200c;
  --cst-chips-potion-text-disabled: #4891ff7f;
  --cst-chips-potion-trailingicon-focused: #4891ff99;
  --cst-checkbox-icon-active: #fdfdfd;
  --cst-chips-potion-trailingicon-hover: #4891ff;
  --cst-drop-listsort-backgroundopen-hover: #fcfcfc;
  --cst-tags-background-ride: var(--alias-properties-ride);
  --cst-chips-potion-text-hover: #4891ff;
  --cst-tags-icon-none: var(--alias-properties-icon-none);
  --cst-chips-potion-text-default: #4891ff99;
  --cst-chips-egg-trailingiconbackground-disabled: #d60f861e;
  --cst-chips-egg-trailingicon-focused: #d60f8699;
  --cst-scroll-scrollbackground-hover: #f6f6f6;
  --cst-chips-egg-trailingicon-pressed: #d60f867f;
  --cst-chips-egg-text-pressed: #d60f867f;
  --cst-input-searchchips-background-default: #f9f9f9;
  --cst-chips-egg-outline-pressed: #d60f8659;
  --cst-chips-egg-background-pressed: #d60f8607;
  --cst-chips-egg-trailingicon-hover: #d60f86;
  --cst-input-searchchips-iconbackground-transport: #6941c63f;
  --cst-input-price-textarea-disabled: #60606033;
  --cst-chips-egg-trailingiconbackground-hover: #d60f8666;
  --cst-chips-egg-text-hover: #d60f86;
  --cst-input-searchchips-outline-transport: #51459f19;
  --cst-input-searchchips-icon-transport: #51459f99;
  --cst-input-price-title-disabled: #8f8f8f33;
  --cst-chips-egg-background-hover: #d60f8619;
  --cst-chips-egg-trailingicon-default: #d60f8699;
  --cst-cards-checkout-info-item: #606060;
  --cst-cards-checkout-info-secondary-item: #9e9d9e;
  --cst-tabitemnavigation-text-active: #606060;
  --cst-checkbox-outline-hover: #6941c64c;
  --cst-checkbox-outline-warning: #ef6363;
  --cst-chips-transport-trailingiconbackground-pressed: #51459f66;
  --cst-cards-checkout-info-total: #303030;
  --cst-chips-transport-text-pressed: #6941c67f;
  --cst-chips-transport-trailingicon-default: #51459f99;
  --cst-checkbox-background-pressed: #f6f6f6;
  --cst-chips-transport-trailingiconbackground-hover: #51459f7f;
  --cst-label-iconbackground-black: #2121217f;
  --cst-input-searchchips-outline-cat: #fe992019;
  --cst-drop-listgame-background: #fdfdfd;
  --cst-chips-potion-trailingiconbackground-focused: #4891ff3f;
  --cst-additional-info-outline-green: #40bb1819;
  --cst-input-searchchips-background-potion: #4891ff0c;
  --cst-chips-potion-text-pressed: #4891ff7f;
  --cst-additional-info-background-green: #40bb180c;
  --cst-additional-info-title-blue: #4891ff;
  --cst-additional-info-outline-blue: #4891ff1a;
  --cst-additional-info-background-blue: #4891ff0d;
  --cst-input-searchchips-iconbackground-potion: #4891ff3f;
  --cst-chips-potion-trailingicon-default: #4891ff99;
  --cst-input-searchchips-icon-egg: #d60f8699;
  --cst-chips-potion-outline-default: #4891ff19;
  --cst-input-searchchips-iconbackground-egg: #d60f863f;
  --cst-drop-listsort-outlineopen-hover: #f6f6f6;
  --cst-tags-text-ride: #fdfdfd;
  --cst-chips-potion-outline-hover: #4891ff7f;
  --cst-input-searchchips-text-egg: #d60f8699;
  --cst-input-searchchips-text-transport: #6941c699;
  --cst-filter-tittle: #303030;
  --cst-input-searchchips-background-transport: #51459f0c;
  --cst-input-searchchips-text-default: #606060;
  --cst-cards-category-icon-hover: #606060;
  --cst-tabitem-primary-background-focused: #ffffff;
  --cst-tabitemnavigation-text-default: #bfbfbf;
  --cst-advertisingbutton-outline-pressed: #fdfdfd;
  --cst-tabitemfilter-text-active: #8f8f8f;
  --cst-chips-egg-text-default: #d60f8699;
  --cst-buttons-quaternary-text-focused: #606060;
  --global-purple900: #2a1a4f;
  --cst-stepperbutton-outline-default: #51459f0d;
  --cst-buttons-quaternary-background-hover: #fdfdfd;
  --global-purple1000: #150d28;
  --cst-cards-item-tertiary-background-default: #fff;
  --cst-stepperbutton-iconbackground-default: #6941c67f;
  --cst-filter-item-text-hover: #606060;
  --cst-cards-item-offer-outline: #f2f2f2;
  --cst-cards-item-offer-outline-pressed: #bfbfbf;
  --cst-cards-item-offer-outline-default: #efefef;
  --cst-cards-item-offer-background: #ffffff;
  --cst-cards-item-offer-background-pressed: #f6f6f6;
  --cst-cards-offer-description-text-default: #8f8f8f;
  --cst-offer-text: #212121;
  --cst-cards-offer-new-price-default: #303030;
  --cst-cards-offer-old-price-default: #8f8f8f;
  --cst-cards-item-offer-new-price: #303030;
  --cst-cards-item-offer-old-price: #8f8f8f;
  --cst-cards-checkout-info-outline: #f2f2f2;
  --cst-tags-text-fly: #fdfdfd;
  --cst-buttons-quaternary-text-hover: #606060;
  --global-purple400: #a58ddd;
  --cst-cards-item-tertiary-newpricetext-default: #303030;
  --cst-stepperbutton-outline-hover: #51459f4c;
  --cst-cards-checkout-info-text: #606060;
  --cst-checkbox-background-focused: #fdfdfd;
  --cst-tags-background-fly: var(--alias-properties-fly);
  --cst-tags-text-discountalternative: #4891ff;
  --cst-stepperbutton-icon-focused: #6941c6;
  --cst-tags-background-discountalternative: #4891ff0c;
  --cst-drop-listsort-textopen-hover: #303030;
  --cst-stepperbutton-outline-pressed: #6941c64c;
  --cst-tags-text-discount: #fdfdfd;
  --cst-stepperbutton-icon-disabled: #51459f07;
  --alias-properties-fly: #108ed4;
  --cst-input-search-background-filled: #fdfdfd;
  --cst-tags-text-hotprice: #fdfdfd;
  --cst-drop-listsort-outlineopen-default: #f6f6f6;
  --cst-input-search-outline-filled: #6941c6;
  --cst-tags-background-hotprice: #eb3c3c;
  --cst-tags-background-discount: #303030;
  --cst-tabitem-tertiary-outline-disabled: #40bb1807;
  --cst-item-card-description: #606060;
  --cst-buttons-primary-text-pressed: #fdfdfd;
  --cst-tabitem-tertiary-background-pressed: #40bb180c;
  --cst-cards-item-block-background: #fdfdfd;
  --cst-cards-item-primary-descriptiontext-default: #8f8f8f;
  --cst-cards-item-primary-title-default: #303030;
  --cst-cards-item-primary-type-text-default: #8f8f8f;
  --cst-tabitem-primary-text-focused: #606060;
  --cst-checkbox-background-hover: #f6f6f6;
  --cst-tabitemnavigation-text-hover: #8f8f8f;
  --cst-diagram-backgroundhandle-hoverup: #40bb1819;
  --cst-buttons-primary-background-pressed: #7f56d9;
  --cst-tabitem-tertiary-text-hover: #40bb18bf;
  --cst-tabitem-tertiary-outline-hover: #40bb1826;
  --cst-cards-item-primary-outline-hover: #f6f6f6;
  --cst-tabitem-secondary-text-default: #eb3c3cbf;
  --cst-tabitem-tertiary-text-default: #40bb18bf;
  --cst-dropitemgame-background-hover: #f6f6f6;
  --cst-diagram-outlinehandle-hoverup: #40bb1866;
  --cst-tabitem-tertiary-outline-default: #40bb1819;
  --cst-diagram-diagram-hoverup: #40bb18;
  --cst-tabitem-tertiary-background-default: #40bb180c;
  --cst-input-search-сursor-focus: #303030;
  --cst-tabitem-tertiary-background-disabled: #40bb1807;
  --cst-tags-background-ultrarare: var(--alias-properties-ultra-rare);
  --cst-chips-transport-trailingicon-disabled: #51459f7f;
  --cst-chips-egg-background-focused: #d60f860c;
  --cst-cards-shoping-item-subtitle: #303030;
  --cst-cards-shoping-item-background-tags: #f6f6f6;
  --cst-cards-shoping-item-new-price: #303030;
  --cst-cards-shoping-item-old-price: #8f8f8f;
  --cst-side-bar-category-title: #303030;
  --cst-side-bar-sale-description: #606060;
  --cst-chips-egg-trailingiconbackground-focused: #d60f863f;
  --cst-cards-shoping-item-backgroundtags: #f6f6f6;
  --cst-tabitem-primary-outline-pressed: #bfbfbf;
  --cst-buttons-primary-text-hover: #fdfdfd;
  --cst-chips-simple-text-focused: #bfbfbf;
  --cst-tabitem-primary-outline-hover: #f6f6f6;
  --cst-item-card-title: #303030;
  --cst-label-primary-text-default: #303030;
  --cst-side-baritems-background-focused: #fdfdfd;
  --cst-tabitem-quaternary-outline-focused: #3f2777;
  --cst-tabitem-quaternary-background-pressed: #f6f6f6;
  --cst-side-baritems-text-hover: #303030;
  --cst-chips-simple-background-pressed: #fdfdfd;
  --cst-side-baritems-background-default: #fdfdfd;
  --cst-tabitem-quaternary-background-hover: #fdfdfd;
  --cst-side-baritems-text-active: #303030;
  --cst-link-title-default: #8f8f8f;
  --cst-tabitem-quaternary-text-default: #606060;
  --cst-side-baritems-trailingicon-active: #6941c6;
  --cst-tabitem-quaternary-background-default: #ffffff;
  --cst-cards-item-tertiary-newpricetext-hover: #303030;
  --cst-filter-item-text-default: #8f8f8f;
  --cst-switch-backgroundon-default: #f0ecf9;
  --cst-tags-background-uncommon: var(--alias-properties-uncommon);
  --cst-switch-iconon-focused: #6941c6;
  --cst-dropitem-background-disabled: #fdfdfd66;
  --global-orange300: #ffd6a6;
  --global-black100: #b5bbc2;
  --cst-label-text-default: #303030;
  --cst-label-secondary-text-default: #bfbfbf;
  --cst-label-secondary-text-hover: #8f8f8f;
  --cst-label-secondary-text-pressed: #606060;
  --cst-tabitem-secondary-text-disabled: #eb3c3c66;
  --cst-stepperbutton-icon-pressed: #6941c6;
  --cst-switch-iconon-default: #6941c6;
  --cst-tags-text-uncommon: #fdfdfd;
  --cst-input-value-text-disabled: #6941c633;
  --alias-properties-neon-rare: #40bb18;
  --cst-tabitem-secondary-background-disabled: #eb3c3c07;
  --cst-input-search-background-hover: #fdfdfd;
  --cst-input-value-cursor-typing: #212121;
  --cst-tabitem-secondary-background-focused: #eb3c3c0c;
  --cst-chips-cat-text-pressed: #fe99207f;
  --cst-input-search-outline-hover: #6941c6;
  --cst-input-value-background-typing: #51459f0c;
  --cst-chips-cat-trailingicon-hover: #fe9920;
  --cst-tabitem-secondary-text-focused: #eb3c3cbf;
  --cst-chips-potion-trailingicon-pressed: #4891ff7f;
  --alias-properties-legendary: #303030;
  --global-rainmaker900: #1c2229;
  --cst-stepperbutton-icon-hover: #6941c6;
  --cst-tabs-cards-background-hover: #efefef;

  --cst-tabs-item-filter-background-default: #fdfdfd;
  --cst-tabs-item-filter-background-active: #f6f6f6;
  --cst-tab-item-quaternary-back-disabled: #d9d9d94d;

  --cst-tabs-item-filter-text-active: #606060;
  --cst-tabs-item-filter-text-default: #8f8f8f;
  --cst-tabs-item-filter-text-hover: #8f8f8f;

  --cst-tabs-item-filter-outline-default: #f2f2f2;
  --cst-tabs-item-filter-outline-hover: #f2f2f2;

  --cst-input-search-text-default: #8f8f8f;
  --cst-input-value-text-typing: #6941c6;
  --cst-chips-cat-background-focused: #fe99200c;
  --cst-tabitem-secondary-text-pressed: #eb3c3c;
  --cst-tabitem-profile-text-default: #bfbfbf;
  --cst-tabitem-profile-icon-active: #6941c6;
  --cst-input-search-outline-focus: #2a1a4f;
  --cst-input-value-text-focus: #6941c6;
  --cst-chips-cat-trailingiconbackground-hover: #fe99207f;
  --cst-tabitem-secondary-text-hover: #eb3c3cbf;
  --cst-input-search-background-focus: #fdfdfd;
  --cst-input-value-outline-focus: #3f2777;
  --cst-additional-info-outline-orange: #fe992019;
  --cst-additional-info-outline-red: #eb3c3c1a;
  --cst-chips-cat-outline-pressed: #fe992059;
  --cst-tabitem-secondary-outline-hover: #eb3c3c26;
  --cst-inputauth-background-focused: #fdfdfd;
  --cst-chips-transport-trailingicon-pressed: #51459f7f;
  --cst-input-value-text-default: #6941c6;
  --cst-switch-iconoff-disabled: #c3b3e8;
  --cst-buttons-quaternary-outline-disabled: #6941c607;
  --cst-chips-transport-text-default: #6941c6;
  --cst-authorization-outline: #f6f6f6;
  --global-green700: #339613;
  --cst-buttons-secondary-text-disabled: #6941c633;
  --cst-buttons-quaternary-text-default: #606060;
  --cst-bread-crumbs-title-default: #bfbfbf;
  --cst-authorization-title: #212121;
  --cst-input-auth-title-filled: #8f8f8f;
  --cst-input-auth-outline-filled: #f6f6f6;
  --cst-input-auth-background-filled: #ffffff;
  --cst-top-up-cash-out-method-title: #303030;
  --cst-input-auth-text-filled: #303030;
  --cst-buttons-quaternary-background-default: #fdfdfd;
  --cst-bread-crumbs-title-hover: #8f8f8f;
  --cst-authorization-background: #ffffff;
  --cst-buttons-secondary-outline-focused: #3f2777;
  --cst-buttons-secondary-outline-hover: #6941c619;
  --cst-buttons-tertiary-outline-disabled: #eb3c3c07;
  --cst-cards-live-feed-backgroundtags-hover: #f6f6f6;
  --cst-buttons-tertiary-text-focused: #eb3c3c;
  --cst-tabitem-primary-text-default: #606060;
  --cst-diagramtooltip-line-primary: #e51a2e;
  --cst-buttons-tertiary-text-pressed: #eb3c3c;
  --cst-cards-live-feed-oldpricetext-hover: #8f8f8f;
  --cst-buttons-secondary-outline-default: #6941c619;
  --cst-tags-background-common: var(--alias-properties-common);
  --cst-tabitem-primary-text-hover: #606060;
  --cst-buttons-quaternary-text-disabled: #8f8f8f66;
  --cst-buttons-tertiary-text-hover: #eb3c3c;
  --cst-buttons-tertiary-outline-hover: #eb3c3c19;
  --cst-buttons-secondary-background-focused: #6941c60c;
  --cst-checkbox-outline-focused: #3f2777;
  --cst-tags-background-mega-neon: var(--alias-properties-mega-neon);
  --cst-cards-checkout-info-particle: #a58ddd;
  --cst-buttons-quaternary-background-disabled: #fdfdfd66;
  --global-green1000: #0d2505;
  --cst-buttons-tertiary-background-hover: #eb3c3c33;
  --cst-buttons-tertiary-outline-default: #eb3c3c19;
  --cst-buttons-secondary-text-pressed: #6941c699;
  --cst-side-barcategory-title: #303030;
  --cst-tags-text-mega-neon: #fdfdfd;
  --cst-cards-checkout-info-discount: #40bb18;
  --cst-buttons-quaternary-outline-focused: #3f2777;
  --cst-authorization-subtitle: #646464;
  --global-green900: #1a4b0a;
  --cst-buttons-secondary-background-pressed: #6941c60c;
  --global-green800: #26700e;
  --cst-buttons-tertiary-background-default: #eb3c3c0c;
  --cst-buttons-quaternary-outline-default: #f6f6f6;
  --cst-buttons-secondary-text-hover: #6941c699;
  --cst-chips-transport-background-hover: #51459f19;
  --cst-checkbox-outline-pressed: #6941c699;
  --cst-stepperbutton-iconbackground-focused: #6941c67f;
  --cst-tags-outline-discountalternative: #4891ff19;
  --cst-filter-item-text-defaultsecondary: #6941c6;
  --cst-drop-listsort-outline-focus: #3f2777;
  --global-green600: #40bb18;
  --cst-buttons-secondary-text-default: #6941c699;
  --cst-cards-secondary-type-text-default: #8f8f8f;
  --cst-chips-transport-outline-hover: #51459f7f;
  --global-rainmaker800: #2b323e;
  --cst-buttons-quaternary-text-pressed: #303030;
  --cst-chips-transport-outline-default: #6941c61a;
  --cst-stepperbutton-background-focused: #51459f0c;
  --cst-tags-backround-discount: #303030;
  --cst-drop-listsort-text-default: #606060;
  --global-green500: #66c946;
  --cst-buttons-secondary-background-default: #6941c60c;
  --cst-tabitem-quaternary-text-focused: #606060;
  --cst-buttons-primary-text-disabled: #fdfdfd66;
  --cst-buttons-primary-outline-focused: #3f2777;
  --cst-tabitemfilter-background-default: #fdfdfd;
  --cst-buttons-primary-text-default: #fdfdfd;
  --cst-chips-simple-background-focused: #fdfdfd;
  --cst-cards-item-page-background: #f2f2f2;
  --cst-input-price-outline-filled: #f2f2f2;
  --cst-buttons-primary-background-default: #6941c6;
  --cst-bread-crumbs-title-pressed: #bfbfbf;
  --cst-switch-handleon-default: #a58ddd;
  --cst-chips-simple-trailingiconbackground-default: #efefef;
  --cst-chips-potion-trailingiconbackground-default: #4891ff3f;
  --cst-dropitem-text-disabled: #60606066;
  --alias-properties-mega-neon: #51459f;
  --cst-link-tittle-hover: #303030;
  --cst-tabitem-tertiary-background-hover: #40bb1819;
  --cst-buttons-tertiary-background-pressed: #eb3c3c26;
  --cst-buttons-tertiary-background-focused: #eb3c3c0c;
  --cst-chips-potion-background-disabled: #4891ff19;
  --cst-plates-background: #f6f6f6;
  --global-white: #ffffff;
  --cst-offer-outline: #f6f6f6;
  --cst-chips-simple-trailingicon-focused: #bfbfbf;
  --cst-side-baritems-text-disabled: #60606066;
  --cst-tabitem-quaternary-outline-disabled: #f6f6f666;
  --cst-chips-simple-trailingiconbackground-disabled: #efefef;
  --cst-side-baritems-background-disabled: #fdfdfd66;
  --global-purple600: #6941c6;
  --cst-cards-item-tertiary-descriptiontext-default: #8f8f8f;
  --cst-tags-text-ultrarare: #fdfdfd;
  --cst-tabitem-quaternary-outline-pressed: #bfbfbf;
  --cst-buttons-primary-background-focused: #6941c6;
  --cst-chips-simple-trailingicon-pressed: #bfbfbf;
  --cst-tabitem-primary-outline-default: #efefef;

  --cst-tabitem-navigation-text-default: #bfbfbf;
  --cst-tabitem-navigation-text-hover: #8f8f8f;
  --cst-tabitem-navigation-text-active: #606060;

  --cst-chips-simple-text-disabled: #bfbfbf;
  --cst-side-baritems-outline-focused: #3f2777;
  --cst-tabitem-quaternary-background-focused: #fdfdfd;
  --cst-buttons-primary-text-focused: #fdfdfd;
  --cst-side-baritems-trailingicon-focused: #6941c6;
  --cst-side-baritems-text-focused: #606060;
  --cst-dropitem-background-pressed: #f9f9f9;
  --cst-dropitem-text-hover: #303030;
  --cst-input-search-text-hover: #606060;
  --cst-input-value-outline-typing: #51459f7f;
  --cst-chips-cat-trailingicon-pressed: #fe99207f;
  --cst-tabitem-secondary-outline-focused: #3f2777;
  --cst-cards-category-outline-hover: #f6f6f6;
  --cst-cards-item-primary-oldpricetext-default: #8f8f8f;
  --cst-tabitem-primary-background-disabled: #f6f6f6;
  --cst-advertisingbutton-text-hover: #fdfdfd;
  --cst-chips-transport-trailingiconbackground-default: #51459f3f;
  --global-purple800: #3f2777;
  --cst-buttons-quaternary-background-focused: #fdfdfd;
  --cst-diagramtooltip-tagtext-primary: #e51a2e;
  --cst-sliders-lineactive: #6941c6;
  --cst-chips-transport-background-pressed: #51459f07;
  --cst-diagramtooltip-date-secondary: #bfbfbf;
  --cst-buttons-secondary-outline-pressed: #6941c64c;
  --cst-sliders-handle: #6941c6;
  --cst-switch-backgroundoff-default: #f0ecf9;
  --cst-chips-transport-outline-pressed: #51459f66;
  --cst-diagramtooltip-outline-primary: #f6f6f6;
  --cst-drop-listsort-background-focus: #fdfdfd;
  --cst-sliders-background: #6941c619;
  --cst-switch-iconoff-default: #6941c6;
  --cst-buttons-tertiary-outline-pressed: #eb3c3c19;
  --cst-diagramtooltip-background-primary: #fdfdfd;
  --cst-drop-listsort-icon-hover: #8f8f8f;
  --cst-sliders-line: #6941c633;
  --cst-switch-handleoff-default: #a58ddd;
  --cst-chips-transport-trailingicon-hover: #51459f;
  --cst-scroll-scroller-hover: #c3b3e8;
  --cst-hyperlink-tittle-default: #d0d0d0;
  --cst-switch-outlineoff-default: #e1d9f4;
  --cst-scroll-scrollbackground-default: #fcfcfc;
  --cst-hyperlink-title-hover: #303030;
  --cst-live-feed-backgroundtabs: #f6f6f6;
  --cst-diagramtooltip-date-primary: #bfbfbf;
  --cst-sliders-outline: #6941c633;
  --cst-stepperbutton-outline-focused: #3f2777;
  --cst-tags-text-legendary: #fdfdfd;
  --cst-input-search-text-filled: #303030;
  --cst-tabitem-tertiary-text-focused: #40bb18bf;
  --cst-diagramtooltip-tagoutline-primary: #e51a2e19;
  --cst-tabitem-tertiary-outline-pressed: #40bb183f;
  --cst-input-searchchips-outline-potion: #4891ff19;
  --cst-additional-info-icon-green: #40bb18;
  --cst-diagramtooltip-tagbackground-primary: #e51a2e0c;
  --cst-buttons-tertiary-text-default: #eb3c3c;
  --cst-dividers-vertical: #f2f2f2;
  --cst-tabitem-tertiary-text-pressed: #40bb18;
  --cst-tabitem-tertiary-text-disabled: #40bb1866;
  --cst-input-price-backgroundtrailingicon-hover: #bfbfbf7f;
  --cst-additional-info-backgroundicon-green: #40bb187f;
  --cst-cards-category-backgroundicon-hover: #bfbfbf7f;
  --cst-cards-item-primary-newpricetext-default: #303030;
  --cst-tabitem-primary-outline-focused: #3f2777;
  --cst-inputauth-background-pressed: #fdfdfd;
  --cst-chips-transport-background-focused: #51459f0c;
  --cst-switch-backgroundoff-disabled: #f0ecf9;
  --cst-input-search-text-focus: #bfbfbf;
  --cst-cards-item-tertiary-oldpricetext-hover: #8f8f8f;
  --cst-tabs-cards-background-active: #fdfdfd;
  --cst-inputauth-outline-focused: #3f2777;
  --cst-chips-transport-outline-focused: #3f2777;
  --cst-switch-outlineoff-disabled: #e1d9f466;
  --global-rainmaker500: #6c7685;
  --cst-tabs-cards-outline-active: #e1d9f4;
  --cst-chips-simple-trailingiconbackground-hover: #bfbfbf;
  --cst-side-baritems-background-active: #eceaea;
  --cst-inputauth-outline-pressed: #6941c699;
  --cst-chips-transport-text-focused: #6941c699;
  --cst-price-card-outline-add: #f2f2f2;
  --cst-tags-text-neon: #fdfdfd;
  --cst-switch-handleoff-disabled: #e1d9f4;
  --cst-bread-crumbs-title-hover-active: #606060;
  --cst-inputauth-title-pressed: #8f8f8f;
  --cst-chips-transport-trailingiconbackground-focused: #51459f3f;
  --cst-tags-background-neon: var(--alias-properties-neon-rare);
  --cst-switch-backgroundoff-focused: #f0ecf9;
  --cst-bread-crumbs-title-active: #606060;
  --global-rainmaker700: #394352;
  --cst-tabitemfilter-background-hover: #fdfdfd;
  --cst-cards-item-primary-background-hover: #fdfdfd;
  --cst-tabitem-secondary-outline-default: #eb3c3c19;
  --cst-tabitem-quaternary-text-pressed: #303030;
  --cst-tabitem-quaternary-outline-hover: #efefef;
  --cst-chips-transport-trailingicon-focused: #51459f99;
  --cst-input-price-title-filled: #8f8f8f;
  --cst-switch-iconoff-focused: #6941c6;
  --cst-input-searchchips-background-cat: #fe99200c;
  --cst-switch-iconoff-pressed: #f0ecf9;
  --cst-tabitem-quaternary-background-disabled: #fdfdfd66;
  --cst-tabitem-quaternary-text-hover: #606060;
  --cst-chips-transport-background-disabled: #51459f19;
  --cst-switch-outlineoff-focused: #3f2777;
  --cst-cards-item-primary-newpricetext-hover: #303030;
  --cst-tabitem-primary-text-disabled: #8f8f8f66;
  --cst-tabitem-quaternary-text-disabled: #8f8f8f66;
  --cst-chips-simple-text-hover: #606060;
  --cst-tabitem-quaternary-outline-default: #f6f6f6;
  --cst-side-baritems-text-default: #606060;
  --cst-badge-background-default: #eb3c3c;
  --cst-chips-transport-text-disabled: #6941c67f;
  --cst-input-searchchips-iconbackground-cat: #fe99203f;
  --cst-switch-handleoff-focused: #a58ddd;
  --cst-tags-background-rare: var(--alias-properties-neon-rare);
  --cst-drop-listsort-backgroundopen-default: #ffffff;
  --cst-tags-text-rare: #fdfdfd;
  --cst-tags-background-none: var(--alias-properties-background-none);
  --cst-input-searchchips-background-egg: #d60f860c;
  --cst-chips-potion-background-pressed: #4891ff07;
  --cst-input-searchchips-text-cat: #fe992099;
  --cst-switch-handleoff-pressed: #c3b3e8;
  --cst-drop-listprofile-text: #303030;
  --cst-tabitemfilter-background-active: #f6f6f6;
  --cst-cards-item-primary-oldpricetext-hover: #8f8f8f;
  --cst-tabitem-secondary-background-default: #eb3c3c0c;
  --cst-chips-transport-trailingiconbackground-disabled: #51459f1e;
  --cst-input-searchchips-text-potion: #4891ff99;
  --cst-switch-backgroundoff-pressed: #f0ecf9;
  --cst-stepperbutton-background-disabled: #51459f07;
  --cst-inputstepper-background-disabled: rgba(105, 65, 198, 0.1);
  --cst-tags-text-common: #fdfdfd;
  --cst-buttons-secondary-text-focused: #6941c699;
  --cst-tabitem-primary-background-pressed: #f6f6f6;
  --cst-diagramtooltip-tip-secondary: #fdfdfd;
  --cst-buttons-tertiary-outline-focused: #3f2777;
  --cst-drop-listsort-iconopen-default: #bfbfbf;
  --cst-advertisingbutton-outline-hover: #efefef;
  --cst-chips-simple-trailingicon-default: #bfbfbf;
  --cst-input-price-trailingicon-focus: #606060;
  --cst-switch-outlineoff-hover: #e1d9f4;
  --cst-diagramtooltip-amount-secondary: #303030;
  --cst-buttons-secondary-outline-disabled: #6941c619;
  --cst-label-icon-black: #212121;
  --cst-tabitem-primary-background-hover: #ffffff;
  --cst-diagramtooltip-background-secondary: #fdfdfd;
  --cst-background-secondary: #f6f6f6;
  --cst-sidebar-password-text: #303030;
  --cst-sidebar-password-description: #606060;
  --cst-buttons-tertiary-text-disabled: #eb3c3c66;
  --cst-tabitem-primary-text-pressed: #303030;
  --cst-diagramtooltip-outline-secondary: #f6f6f6;
  --cst-buttons-tertiary-background-disabled: #eb3c3c07;
  --cst-drop-listsort-textopen-default: #606060;
  --cst-advertisingbutton-text-pressed: #fdfdfd;
  --cst-switch-outlineoff-pressed: #e1d9f4;
  --cst-input-searchchips-icon-cat: #fe992099;
  --cst-diagramtooltip-icon-secondary: #40bb18;
  --cst-cards-category-background-default: #ffff;
  --cst-input-search-outline-default: #6941c6;
  --cst-input-searchchips-outline-egg: #d60f8619;
  --cst-chips-potion-background-hover: #4891ff19;
  --cst-chips-potion-trailingiconbackground-hover: #4891ff7f;
  --cst-checkbox-background-active: #6941c6;
  --cst-chips-transport-text-hover: #6941c6;
  --cst-chips-potion-background-focused: #4891ff0c;
  --cst-input-searchchips-icon-potion: #4891ff99;
  --cst-chips-potion-outline-pressed: #4891ff59;
  --cst-input-price-cursor-focus: #606060;
  --cst-switch-handleoff-hover: #8767d1;
  --cst-chips-transport-background-default: #6941c60d;
  --cst-input-price-title-typing: #8f8f8f;
  --cst-switch-iconoff-hover: #f0ecf9;
  --cst-drop-listgame-outline: #f6f6f6;
  --cst-chips-potion-trailingiconbackground-pressed: #4891ff59;
  --cst-switch-outlineon-default: #e1d9f4;
  --cst-tabitem-quaternary-back-disabled: #d9d9d94c;
  --cst-buttons-primary-background-disabled: #c3b3e8;
  --cst-link-background-loading: #f2f2f2;
  --cst-input-price-outline-typing: #2a1a4f;
  --cst-switch-backgroundoff-hover: #f0ecf9;
  --cst-inputauth-outline-disabled: #f6f6f633;
  --cst-dividers-horizontal: #f2f2f2;
  --cst-input-search-background-default: #fdfdfd;

  --rarity-common-background100: #4891ff;
  --rarity-uncommon-background100: #6941c6;
  --rarity-ultra-rare-background100: #e51a2e;
  --rarity-rare-background100: #40bb18;
  --rarity-legendary-background100: #1e1e1e;

  /* typography variables */

  /* sizes */
  --font-size-5x-large: 48px;
  --font-size-4x-large: 32px;
  --font-size-3x-large: 28px;
  --font-size-2x-large: 24px;
  --font-size-x-large: 20px;
  --font-size-large: 18px;
  --font-size-medium: 16px;
  --font-size-base: 14px;
  --font-size-small: 12px;
  --font-size-x-small: 10px;
  /* sizes */

  /*default-color*/
  --alias-typography-primary: #27272a;
  /*default-color*/

  /*font-weight*/
  --font-weight-bold: 700;
  --font-weight-semi-bold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  /*font-weight*/

  /*line-height*/
  --line-height-5x-large: calc(var(--font-size-5x-large) * 1.5);
  --line-height-4x-large: calc(var(--font-size-4x-large) * 1.5);
  --line-height-3x-large: calc(var(--font-size-3x-large) * 1.5);
  --line-height-2x-large: calc(var(--font-size-2x-large) * 1.5);
  --line-height-x-large: calc(var(--font-size-x-large) * 1.5);
  --line-height-large: calc(var(--font-size-large) * 1.5);
  --line-height-medium: calc(var(--font-size-medium) * 1.5);
  --line-height-base: calc(var(--font-size-base) * 1.5);
  --line-height-small: calc(var(--font-size-small) * 1.5);
  --line-height-x-small: calc(var(--font-size-x-small) * 1.5);
  /*font-weight*/

  /*font-style*/
  --font-style-normal: normal;
  --font-style-underline: underline;
  --font-style-strikethrough: line-through;
  /*font-style*/

  /*typography variables*/

  /*box shadow effects*/
  --elevation-1: 0px 4px 18px 0px rgba(0, 0, 0, 0.03);
  --elevation-2: 0px -4px 18px 0px rgba(0, 0, 0, 0.03);
  --elevation-3: 0px -5px 18px 0px rgba(0, 0, 0, 0.03);
  --elevation-4: 0px 3px 13.5px 0px rgba(0, 0, 0, 0.03);
  /*box shadow effects*/
}

/* scroll style */
textarea::-webkit-scrollbar,
ul::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 4px;
  height: 7px;
  padding-right: 10px;
  background-color: transparent;
}

textarea::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  width: 4px;
  background-color: var(--cst-scroll-scrollbackground-default);
  border-radius: 5px;
}

textarea::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background: var(--cst-scroll-scroller-default);
  border-radius: 5px;
}

textarea::-webkit-scrollbar-thumb:hover,
div::-webkit-scrollbar-thumb:hover,
ul::-webkit-scrollbar-thumb:hover {
  background: var(--cst-scroll-scroller-hover);
}

textarea::-webkit-scrollbar-track:hover,
ul::-webkit-scrollbar-track:hover,
div::-webkit-scrollbar-track:hover {
  background-color: var(--cst-scroll-scrollbackground-hover);
}
/*firefox*/
div,
textarea,
ul {
  scrollbar-color: var(--cst-scroll-scroller-default)
    var(--cst-scroll-scrollbackground-default);
  scrollbar-width: thin;

  &:hover {
    scrollbar-color: var(--cst-scroll-scroller-hover)
      var(--cst-scroll-scrollbackground-hover);
    scrollbar-width: thin;
  }
}
/*firefox*/
/* scroll style*/
